<template>
	<div class="employee-table">
		<v-data-table
			class="overflow-x elevation-1"
			height="100%"
			item-key="_id"
			hide-default-footer
			ref="table"
			:headers="headers"
			:items="items"
			:loading="loading"
			:page.sync="options.page"
			:options="options"
			:server-items-length="options.itemsLength"
			@click:row="handleRowClick"
		>
			<!--  ХЭДЕР ТАБЛИЦЫ -->
			<template v-slot:top="{ pagination, options, updateOptions }">
				<v-data-footer
					class="justify-end pa-3 border-bottom"
					:pagination="pagination"
					:options="options"
					@update:options="updateOptions"
					items-per-page-text=""
					:items-per-page-options="[]"
					disable-items-per-page
					:disable-pagination="loading"
					:show-first-last-page="true"
				>
					<!-- <template v-slot:page-text>
						{{ options.page || "-" }} из {{ options.pageCount || "-" }}
					</template> -->
					<template v-slot:prepend>
						<div class="d-flex flex-sm-grow-1 align-center justify-start">
							<v-btn
								v-if="base_role !== 'administrator'"
								class="mr-5"
								color="primary"
								@click="showModalCreate = true"
								small
								depressed
							>
								<v-icon class="mr-1" small>mdi-plus</v-icon>
								<span class="body-2 font-weight-light">Создать</span>
							</v-btn>
							<v-btn
								class="mr-5 text-lowercase"
								color="primary"
								small
								outlined
								@click="resetFilters"
							>
								<span class="body-2 font-weight-light">
									<span class="text-capitalize">Сбросить</span> фильтры
								</span>
							</v-btn>
							<!-- <v-btn
								color="primary"
								small
								depressed
								:loading="isExportingExcel"
								@click="exportExcel"
							>
								<v-icon class="mr-1" small>mdi-microsoft-excel</v-icon>
								<span class="body-2 font-weight-light text-capitalize">
									Экспорт
								</span>
							</v-btn> -->
						</div>
					</template>
				</v-data-footer>
			</template>
			<!-- Контент таблицы, если список пуст -->
			<template v-if="noItems" v-slot:[`no-data`]>
				<div
					class="py-6 d-flex flex-column align-center justify-center"
					ref="noData"
				>
					<span class="mb-2 body-1 font-weight-light">Список пуст</span>
					<v-btn
						class="subtitle-2 font-weight-light"
						color="primary"
						depressed
						@click="showModalCreate = true"
					>
						Добавить сотрудника
					</v-btn>
				</div>
			</template>
			<!-- ФИЛЬТРЫ ТАБЛИЦЫ -->
			<template v-slot:[`body.prepend`]>
				<tr>
					<td v-if="base_role === 'administrator'">
						<v-combobox
							v-model="filter.ksk_id"
							placeholder="Поиск по УК"
							class="text-caption border-all"
							solo
							multiple
							item-text="name"
							item-value="id"
							:return-object="false"
							:items="kskListConfirm"
							@change="updateQuery"
							dense
							hide-details
							flat
						>
							<template v-slot:selection="{ item, index }">
								<span v-if="index === 0">
									{{ currentKsk(item) }}
								</span>
								<span
									v-if="index === 1"
									class="grey--text text-caption"
								>&nbsp;
									(+ еще {{ filter.ksk_id.length - 1 }})
								</span>
							</template>
						</v-combobox>
					</td>
					<!-- Fullname -->
					<td>
						<v-autocomplete
							v-if="base_role !== 'administrator'"
							v-model="filter.fio"
							class="border-all"
							placeholder="ФИО"
							solo
							dense
							flat
							hide-details
							:item-text="e => e.name || e.fullname"
							item-value="id"
							:items="[...employee_names]"
							@change="updateQuery"
							:disabled="loading"
						>
						</v-autocomplete>
					</td>
					<!-- employee_position -->
					<td>
						<v-autocomplete
							v-model="filter.employee_position"
							class="border-all"
							placeholder="Должность"
							solo
							dense
							flat
							hide-details
							clearable
							item-text="name"
							item-value="id"
							:items="[{ name: 'Все', id: null }, ...catalog.employee_positions]"
							@change="updateQuery"
						>
						</v-autocomplete>
					</td>
					<!-- phone -->
					<td>
						<v-text-field
							v-model="filter.phone"
							:placeholder="phoneCode+'-'"
							v-mask="phoneCode == '+7' ? phoneCode+'-###-###-##-##' : phoneCode+'-###-###-####'"
							class="border-all"
							solo
							dense
							flat
							hide-details
							clearable
							@change="updateQuery"
						>
						</v-text-field>
					</td>
					<!-- houses_ids -->
					<td v-if="base_role !== 'administrator'">
						<!-- <v-autocomplete
							v-model="filter.houses_ids"
							class="border-all"
							solo
							dense
							flat
							hide-details
							clearable
							item-text="apartment_address"
							item-value="_id"
							:items="houses"
							@change="updateQuery"
						>
						</v-autocomplete> -->
						<v-combobox
							v-model="filter.houses_ids"
							placeholder="Адрес"
							class="text-caption border-all"
							solo
							multiple
							item-text="apartment_address"
							item-value="id"
							:return-object="false"
							:items="houses"
							@change="updateQuery"
							dense
							hide-details
							flat
						>
							<template v-slot:selection="{ item, index }">
								<span v-if="index === 0 && item.length > 1">
									{{ currentHouses(item) }}
								</span>
								<span
									v-if="index === 1"
									class="grey--text text-caption"
								>&nbsp;
									(+ еще {{ filter.houses_ids.length - 1 }})
								</span>
							</template>
						</v-combobox>
					</td>
					<!-- rating -->
					<td v-if="base_role !== 'administrator'"></td>
					<!-- role_id -->
					<td>
						<v-combobox
							v-model="filter.role_id"
							placeholder="Роль"
							class="text-caption border-all"
							solo
							multiple
							item-text="name"
							item-value="id"
							:return-object="false"
							:items="roles"
							@change="updateQuery"
							dense
							hide-details
							flat
						>
							<template v-slot:selection="{ item, index }">
								<span v-if="index === 0 && item.length > 1">
									{{ currentRoles(item) }}
								</span>
								<span
									v-if="index === 1"
									class="grey--text text-caption"
								>&nbsp;
									(+ еще {{ filter.houses_ids.length - 1 }})
								</span>
							</template>
						</v-combobox>
						<!-- <v-autocomplete
							v-model="filter.role_id"
							class="border-all"
							solo
							dense
							flat
							hide-details
							clearable
							item-text="name"
							item-value="id"
							:items="[{ name: 'Все', id: null }, ...catalog.roles]"
							@change="updateQuery"
						>
						</v-autocomplete> -->
					</td>
					<!-- iin -->
					<td>
						<v-text-field
							v-model="filter.iin"
							v-mask="'############'"
							class="border-all"
							placeholder="ИИН"
							solo
							dense
							flat
							hide-details
							clearable
							@change="updateQuery"
						></v-text-field>
					</td>
					<!-- created_at -->
					<td>
						<DatePickerPlain
							:date.sync="filter.created_at"
							:in-table="true"
							nudge-left="115px"
							@change="updateQuery"
						/>
					</td>
				</tr>
			</template>
			<!-- ПОЛЯ ТАБЛИЦЫ -->
			<template v-slot:[`item.company`]="{ item }">
				<td>
					<div v-if="item.ksk">
						<template v-for="(ksk, i) in item.ksk">
							<p :key="i">
								{{ksk.name}}
							</p>
						</template>
					</div>
					<div v-else>---</div>
				</td>
			</template>
			<template v-slot:[`item.fio`]="{ item }">
				<td>
					{{ item | fullname }}
				</td>
			</template>
			<template v-slot:[`item.employee_position`]="{ item }">
				<td>
					{{ item.employee_positions.length ? item.employee_positions[0].employee.name : "" }}
				</td>
			</template>
			<template v-slot:[`item.created_at`]="{ item }">
				<td>
					{{ item.created_at | timestamp }}
				</td>
			</template>
			<template v-slot:[`item.rating`]="{ item }">
				<td>
					{{ item.rating || "---" }}
				</td>
			</template>
			<template v-slot:[`item.houses_ids`]="{ item }">
				<td class="py-2">
					<template v-for="(ksk_houses, i) in item.ksk_houses">
						<span v-if="ksk_houses.house" :key="i">{{ksk_houses.house.apartment_address}} </span>
					</template>
					<!-- {{ getHouses(item.houses_ids) }} -->
				</td>
			</template>
			<template v-slot:[`item.role_id`]="{ item }">
				<td>
					{{ item.role ? item.role.name : "---" }}
				</td>
			</template>
		</v-data-table>
		<!-- ОКНО СОЗДАНИЯ ЗАЯВКИ -->
		<EmployeeTableModal
			v-if="showModalCreate"
			@update-info="updateQuery(false, true)"
			@close-modal="showModalCreate = false"
		/>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { timestampToDdmmyyyy } from "@/helpers/helpers";

import DatePickerPlain from "@/components/app/DatePickerPlain";
import EmployeeTableModal from "@/components/EmployeeTableModal";

export default {
	name: "EmployeeTable",
	components: {
		DatePickerPlain,
		EmployeeTableModal,
	},
	data() {
		return {
			items: [],
			roles: [],
			kskListConfirm: [],
			employeesLoaded: false,
			filter: {
				created_at: this.$route.query.created_at || "",
				employee_position: this.$route.query.employee_position_ids || "",
				fio: this.$route.query.user_ids || [],
				houses_ids: this.$route.query.houses_id || "",
				iin: this.$route.query.iin || "",
				phone: this.$route.query.phone || "",
				rating: this.$route.query.rating || "",
				role_id: this.$route.query.role_id || "",
				ksk_id: this.$route.query.company_ids || null,
			},
			filtersQuery: {
				"created_at": "created_at_first",
				"employee_position": "employee_position_ids[]",
				"fio": "user_ids",
				"houses_ids": "ksk_houses_ids",
				"iin": "iin",
				"phone": "phone",
				"rating": "rating",
				"role_id": "role_ids",
				"ksk_id": "company_ids",
			},
			headers: [
				{ text: "ФИО", value: "fio", width: "250px", sortable: false },
				{
					text: "Должность",
					value: "employee_position",
					width: "250px",
					sortable: false,
				},
				{ text: "Телефон", value: "phone", width: "250px", sortable: false },
				{ text: "Дома", value: "houses_ids", width: "400px", sortable: false },
				{
					text: "Средняя оценка",
					value: "rating",
					width: "200px",
					sortable: false,
				},
				{ text: "Роль", value: "role_id", width: "200px", sortable: false },
				{ text: "ИИН", value: "iin", width: "200px", sortable: false },
				{
					text: "Дата регистрации",
					value: "created_at",
					width: "200px",
					sortable: false,
				},
			],
			options: {
				// role_id: +this.$route.query.role_id ,
				page: +this.$route.query.page || 1,
				itemsPerPage: 10,
				pageStart: 1,
				pageCount: 0,
				itemsLength: 0,
			},
			loading: true,
			noItems: false,
			currentPage: 1,
			firstFetch: false,
			isExportingExcel: false,
			showModalCreate: false,
		};
	},
	watch: {
		options: {
			handler() {
				if(this.firstFetch) {
					const query = { ...this.$route.query, page: this.options.page };
					this.$router.replace({ query });
					if(this.options.page !== this.currentPage) {
						this.loadData(query);
					}
				}
			},
			deep: true,
		},
	},
	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			userData: "auth/GET_INDIVIDUAL_DATA",
			employee_names: "ksk/GET_EMPLOYEE_NAMES",
			houses: "ksk/GET_HOUSES",
			kskId: "auth/GET_KSK_ID",
			companyType: "auth/GET_COMPANY_TYPE",
			base_role: "auth/GET_USER_ROLE",
			phoneCode: "auth/GET_USER_PHONE_CODE",
			// kskListConfirm: "catalog/GET_KSK_LIST_CONFIRM",
		}),
		itemsForManager() {
			return this.items.filter(e => e.roles[0] !== 'ksk_manager')
		},
	},
	methods: {
		timestampToDdmmyyyy,
		disablePagination() {
			let res;
			if(this.loading == true) {
				console.log('loading true', this.loading)
				res = true
				return res
			} else {
				console.log('loading false', this.loading)
				setTimeout(() => {
					console.log('loading false 2 -', this.loading)
					res = false
					return res
				}, 5000);
			}
		},
		currentKsk(item) {
			if(item) {
				const result = this.kskListConfirm.find(({ _id }) => _id === item);
				return result.name
			}
		},
		currentHouses(item) {
			if(item.length > 1) {
				const result = this.houses.find(({ id }) => id === item);
				return result?.apartment_address
			}
		},
		currentRoles(item) {
			if(item.length > 1) {
				const result = this.catalog.roles.find(({ id }) => id === item);
				return result?.name
			}
		},
		getHouses(houses_ids) {
			if (!houses_ids.length) return "---";
			const houses = houses_ids
				.map(e => this.houses.find(h => h.id === e))
				.map(e => e?.apartment_address)
				.filter(e => !!e);
			return houses.join(", ");
		},
		// TODO: как-нибудь переделать с refs
		scrollTableToCenter() {
			const tableWrapper = this.$el.querySelector(".v-data-table__wrapper");
			const table = this.$el.querySelector(".v-data-table__wrapper>table");
			tableWrapper.scrollLeft =
				(table.offsetWidth - tableWrapper.offsetWidth) / 2;
		},
		// ? Начало методов таблица
		handleRowClick(e) {
			if (window.getSelection().toString()) {
				return;
			}
			this.$router.push({
				path: `/employee/${e.id}`,
				query: this.$route.query,
			});
		},

		async exportExcel() {
			this.isExportingExcel = true;
			const query = this.updateQuery(true);
			console.log(query);
			try {
				const res = await this.$api.ksk.export_employee_list(query);
				const link = document.createElement("a");
				link.href = res;
				link.download = `Сотрудники ${timestampToDdmmyyyy()}.xlsx`;
				link.click();
			} catch (error) {
				console.log(error);
			} finally {
				this.isExportingExcel = false;
			}
		},

		async resetFilters() {
			for (const key in this.filter) {
				this.filter[key] = "";
			}
			this.options.page = 1
			this.$router.replace({ query: null });
			await this.loadData({});
		},

		async loadData(params, cache) {
			console.log('params!', params)
			console.log('cache!', cache)
			this.loading = true;
			if(cache) {
				params.forget_cache = true
			}
			if(this.base_role == 'ksk_manager') {
				if(!params.hasOwnProperty('ksk_houses_ids')) {
					params.ksk_houses_ids = this.userData.ksk_houses.map(item => item.house.id);
				}
				// params.ksk_category_ids = this.userData.ksk_category.map(item => item.id);
				params.company_ids = [this.kskId]
			}
			if(this.base_role == 'ksk') {
				params.company_ids = [this.kskId]
			} 
			if(!params.hasOwnProperty('role_ids') || !params.role_ids.length) {
				params.role_ids = []
				this.roles.forEach(role => {
					params.role_ids.push(role.id);
				});
			}
			if(!params.hasOwnProperty('page')) params.page = 1
			params.page_count = 10
			if(!params.hasOwnProperty('role_ids') || !params.role_ids.length) {
				if(this.base_role === 'administrator') {
					params.role_ids = [
						"61fba08f69d79635b83c1d27",
						"5f4cda2f0796c90b114f5537",
						"5f4cda2f0796c90b114f5536",
						"5f4cda2f0796c90b114f5535"
					]
				}
			}
			if(this.base_role !== 'administrator' && this.base_role !== 'dispatcher') {
				params.selected_ksk_id = this.kskId
			}
			if (params.user_ids && params.user_ids.length > 0) {
				params.user_ids = [params.user_ids]
			}
			try {
        this.$api.ksk
				.load_employee({ ...params, paginate: true })
				.then(res => {
					// this.options.page = res.page;
					this.currentPage = res.currentPage
					this.options.pageCount = res.pages;
					this.options.itemsLength = res.pages * 10;
					this.items = res.records;
					this.loading = false
					this.firstFetch = true
				})
      } catch(error) {
        console.log('error', error.response)
      }
		},

		updateQuery(getQuery = false, cache) {
			const query = Object.entries(this.filter).reduce((acc, [key, val]) => {
				if (!key || !val || !this.filtersQuery[key]) return acc;
				return { ...acc, [this.filtersQuery[key]]: val };
			}, {});

			if (getQuery === true) {
				return query;
			}
			this.options.page = 1
			console.log('query', query)
			this.$router.replace({ query: { ...query, page: this.options.page } });
			if(cache) {
				this.loadData(query, cache);
			} else {
				this.loadData(query);
			}
		},
		// ? Конец методов таблицы
	},
	async created() {
		this.$store.dispatch("ksk/load_houses");
		this.$store.dispatch("ksk/load_employee");
		if(this.companyType === 'sc') {
			this.$store.dispatch("auth/load_data")
		}
		if(this.base_role === 'administrator') {
			this.kskListConfirm = await this.$api.dropdowns.load_ksk_list_confirm_admin()
			this.headers.unshift({
				text: "УК",
				value: "company",
				width: "200px",
				sortable: false,
			},)
			this.headers = this.headers.filter(header => header.value !== 'rating' && header.value !== 'houses_ids');
		}
		if(this.$route.query.user_ids) {
			this.filter.fio = this.$route.query.user_ids
		}
	},
	async mounted() {
		try {
			// if(this.base_role == 'ksk_manager') {
			// 	this.roles = this.catalog.roles.filter(role => {
			// 		return role.name_system === 'ksk_employee' || role.name_system === 'ksk_dispatcher';
			// 	});
			// }
			if(this.base_role !== 'administrator') {
				this.roles = this.catalog.roles.filter(role => {
					return role.name_system === 'ksk_employee' || role.name_system === 'ksk_dispatcher' || role.name_system === 'ksk_manager';
				});
			} else {
				this.roles = this.catalog.roles.filter(role => {
					return role.name_system === 'ksk_employee' || role.name_system === 'ksk_dispatcher' || role.name_system === 'ksk_manager' || role.name_system === 'ksk';
				});
			}
			// if(this.base_role == 'ksk') {
			// 	this.roles = this.catalog.roles.filter(role => {
			// 		return role.name_system === 'ksk_employee' || role.name_system === 'ksk_dispatcher' || role.name_system === 'ksk_manager';
			// 	});
			// }

			const query = { ...this.$route.query };
			await this.loadData(query);

			// Если в списке изначально нет элементов, то отцентрировать таблицу
			// if (!this.items.length) {
			// 	console.log('items', this.items)
			// 	this.noItems = true;
			// 	this.scrollTableToCenter();
			// }
		} catch (error) {
			console.error(error);
		}
	},
};
</script>

<style lang="scss" scoped>
.notifications-table {
	height: 100vh;
}
</style>
